import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { I18nContext } from 'common/useT';
import Widget from 'components/Dashboard/Widget';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { DeviceConnectionStatus, GetFleetDeviceStatusesDoc } from 'generated/graphql';
import { DashboardWidget } from 'types/widgets';
import { useQ } from 'utils/apolloClient';
import DonutSegmentChart from 'components/Charts/DonutSegmentChart';
import { theme } from 'twin.macro';
import NoDataResponse, { DataResponse } from 'atoms/NoDataResponse';
import { RatingColor } from 'utils';

const colorMap: Record<DeviceConnectionStatus, RatingColor> = {
  [DeviceConnectionStatus.Connected]: RatingColor.DarkGreen,
  [DeviceConnectionStatus.NotReporting]: RatingColor.Orange,
  [DeviceConnectionStatus.NeverConnected]: RatingColor.Yellow,
  [DeviceConnectionStatus.Disconnected]: RatingColor.DarkRed,
};

const DeviceStatusWidget = () => {
  const i18nContext = useContext(I18nContext);

  const currentFleetId = useCurrentFleetId();
  const { loading, error, data } = useQ(GetFleetDeviceStatusesDoc, {
    variables: {
      fleetId: currentFleetId,
    },
  });

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { dashboardWidgetDescriptionMap, deviceConnectionStatusDescriptionMap },
    },
    tSafe,
  } = i18nContext;

  if (error) return <NoDataResponse message={DataResponse.FailedToFetch} />;

  const pieData =
    data?.deviceStatuses
      ?.map(({ connectionStatus, count }) => {
        return {
          text: deviceConnectionStatusDescriptionMap[connectionStatus],
          value: count ?? 0,
          color: colorMap[connectionStatus as DeviceConnectionStatus],
          link: `${currentFleetId}/list-view?deviceStatus=${connectionStatus}`,
        };
      })
      .filter((x) => x.value)
      .sort((a, b) => {
        return b.value - a.value;
      }) ?? [];

  return (
    <Widget title={dashboardWidgetDescriptionMap[DashboardWidget.deviceStatusWidget]} loading={loading} error={error}>
      {data?.deviceStatuses.length ? (
        <DonutSegmentChart
          data={pieData}
          totalCount={data?.totalCount ?? 0}
          valueType={tSafe('common.domain.device.fields.devices', {
            defaultValue: 'devices',
          })}
          legendColumnCount={3}
        />
      ) : (
        <NoDataResponse message={DataResponse.NoFleetData} />
      )}
    </Widget>
  );
};

export default DeviceStatusWidget;
