import Button from 'atoms/Button';
import ButtonWithConfirmModal from 'atoms/ButtonWithConfirmModal';
import IonIcon from 'atoms/IonIcon';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import { format } from 'date-fns';
import { FuelPriceDate } from 'generated/graphql';
import { useContext } from 'react';
import { cx, formatCurrency } from 'utils';
import EditableInput from './EditableInput';

export enum FuelField {
  Diesel,
  Petrol,
}
export enum FuelPriceAction {
  Create,
  Update,
  Delete,
}

interface FuelRowProps {
  item: { date: string; dieselPrice?: number | null; petrolPrice?: number | null };
  editedRow?: { date?: string; field?: FuelField } | null;
  setEditedRow: (row: { date: string; field: FuelField } | null) => void;
  setNewDieselPrice: (price: number | null) => void;
  setNewPetrolPrice: (price: number | null) => void;
  handleFuelPriceChange: (item: FuelPriceDate, action: FuelPriceAction) => void;
  newDieselPrice?: number | null;
  newPetrolPrice?: number | null;
}

const FuelRow = ({
  item,
  editedRow,
  setEditedRow,
  setNewDieselPrice,
  setNewPetrolPrice,
  handleFuelPriceChange,
  newDieselPrice,
  newPetrolPrice,
}: FuelRowProps) => {
  const { currencyUnit } = useSettings();
  const i18nContext = useContext(I18nContext);
  const isEdited = editedRow?.date === item.date;

  const editPrice = (field: FuelField) => {
    setEditedRow({ date: item.date, field });
    setNewDieselPrice(item.dieselPrice ?? newDieselPrice ?? 0);
    setNewPetrolPrice(item.petrolPrice ?? newPetrolPrice ?? 0);
  };

  const EditablePriceField = ({
    field,
    price,
    setPrice,
  }: {
    field: FuelField;
    price?: number | null;
    setPrice?: (val: number | null) => void;
  }) =>
    isEdited && editedRow?.field === field ? (
      <EditableInput
        defaultValue={price?.toFixed(2)}
        value={(field === FuelField.Diesel ? newDieselPrice : newPetrolPrice)?.toFixed(2) ?? price?.toFixed(2) ?? ''}
        onChange={(e) => setPrice?.(Number(e.target.value))}
      />
    ) : (
      <div className="group grow w-4 flex-center hover:cursor-pointer" onClick={() => editPrice(field)}>
        {price ? formatCurrency(price, currencyUnit) : ''}
        <IonIcon name="pencilOutline" className="text-md ml-1 invisible group-hover:visible" />
      </div>
    );

  if (!i18nContext) return null;
  const {
    tSafe,
    commonTranslations: {
      forms: {
        buttons: { cancel_text, delete_text, save_text },
      },
    },
  } = i18nContext;

  return (
    <li
      className={cx(
        isEdited && 'bg-gray-200',
        'flex h-5 hover:bg-gray-200 items-center text-lg border-b border-gray-300 rounded-4',
      )}
    >
      <h2 className="group flex flex-[0_0_160px] p-1">{format(new Date(item.date), 'do MMMM')}</h2>
      <EditablePriceField field={FuelField.Diesel} price={item.dieselPrice} setPrice={setNewDieselPrice} />
      <EditablePriceField field={FuelField.Petrol} price={item.petrolPrice} setPrice={setNewPetrolPrice} />
      <div className="w-8 flex flex-end space-x-1">
        {isEdited ? (
          <>
            <Button
              className="px-2 py-0.5 bg-primary text-white rounded-8 hover:shadow hover:font-semibold"
              onClick={() =>
                handleFuelPriceChange(
                  {
                    date: item.date,
                    dieselPrice: newDieselPrice ?? item.dieselPrice,
                    petrolPrice: newPetrolPrice ?? item.petrolPrice,
                  },
                  FuelPriceAction.Update,
                )
              }
            >
              {save_text}
            </Button>
            <Button onClick={() => setEditedRow(null)} className="p-1 px-1.5 text-md rounded-8 hover:bg-gray-300">
              {cancel_text}
            </Button>
          </>
        ) : (
          <Tooltip text={delete_text}>
            <ButtonWithConfirmModal
              confirmButtonContainerClassName="h-[30px] mt-2 flex-center space-x-2"
              confirmYesButtonClassName="hover:border-px border-black bg-error text-white rounded-4 px-1.5 py-1"
              confirmNoButtonClassName="hover:border-px border-black bg-gray-400 text-white rounded-4 px-1.5 py-1"
              confirmButtonText={delete_text}
              className="p-1 rounded-4 hover:text-white hover:bg-error"
              confirmTitle={tSafe('components.Settings.FuelPriceSettings.delete-fuel-price', {
                defaultValue: 'Delete Fuel Price',
              })}
              confirmContent={tSafe('components.Settings.FuelPriceSettings.delete-fuel-price-confirmation', {
                defaultValue: 'Are you sure you want to delete this fuel price?',
              })}
              onConfirm={() => handleFuelPriceChange(item, FuelPriceAction.Delete)}
            >
              <IonIcon name="trashOutline" />
            </ButtonWithConfirmModal>
          </Tooltip>
        )}
      </div>
    </li>
  );
};

export default FuelRow;
