import { PropsWithChildren, useContext, useState } from 'react';
import NoDataResponse, { DataResponse } from 'atoms/NoDataResponse';
import { defaultDashboardTimePeriod } from 'common/constants';
import { I18nContext } from 'common/useT';
import Widget from 'components/Dashboard/Widget';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { GetLeaderboardDoc, ListSortDirection, LeaderboardType, Period, LeaderboardSortType } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';
import { Result } from 'types';
import { CellProps } from 'react-table';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useHistory } from 'react-router-dom';
import { cx } from 'utils';
import IonIcon from 'atoms/IonIcon';
import Separator from 'atoms/Separator';
import { CenteredSpinner } from 'atoms/Spinner';
import { LeaderbordColumnId, useLeaderboardColumns } from './useLeaderboardColumns';
import { DashboardWidget } from 'types/widgets';
import DateFilter from '../DateFilter';

type LeaderboardItem = Result<typeof GetLeaderboardDoc>[0];

export type LeaderboardCell<T> = PropsWithChildren<CellProps<LeaderboardItem, T>>;

interface LeaderbordSortedColumn {
  columnId: LeaderbordColumnId;
  direction?: ListSortDirection;
}

const columnIdLeaderboardTypeMap: Partial<Record<LeaderbordColumnId, LeaderboardType>> = {
  [LeaderbordColumnId.EcoScore]: LeaderboardType.EcoScore,
  [LeaderbordColumnId.FuelEfficiency]: LeaderboardType.FuelEfficiency,
  [LeaderbordColumnId.IdleTimePercentage]: LeaderboardType.Idling,
};

const DriverLeaderboardWidget = () => {
  const i18nContext = useContext(I18nContext);
  const history = useHistory();
  const currentFleetId = useCurrentFleetId();
  const [period, setPeriod] = useState<Period>(defaultDashboardTimePeriod);
  const [sortedColumn, setSortedColumn] = useState<LeaderbordSortedColumn | null>({
    columnId: LeaderbordColumnId.EcoScore,
    direction: ListSortDirection.Asc,
  });

  const { loading, error, data, previousData } = useQ(GetLeaderboardDoc, {
    fetchPolicy: 'cache-first',
    variables: {
      period,
      fleetId: useCurrentFleetId(),
      type: columnIdLeaderboardTypeMap[sortedColumn?.columnId ?? LeaderbordColumnId.EcoScore]!,
      sortType: sortedColumn?.direction === ListSortDirection.Desc ? LeaderboardSortType.Asc : LeaderboardSortType.Desc,
    },
  });

  const leaderboard = loading ? previousData?.leaderboard : data;

  const table = useReactTable({
    columns: useLeaderboardColumns(),
    data:
      leaderboard?.map((x) => ({
        driverId: x.driverId,
        ranking: x.ranking,
        name: x.name,
        avgEcoScore: x.avgEcoScore,
        idleTimePercentage: x.idleTimePercentage,
        fuelEfficiency: x.fuelEfficiency,
      })) ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { dashboardWidgetDescriptionMap },
    },
  } = i18nContext;

  return (
    <Widget
      title={dashboardWidgetDescriptionMap[DashboardWidget.driverLeaderboardWidget]}
      filterComponent={<DateFilter selectedPeriod={period} onSelect={(option) => setPeriod(option.value)} />}
      error={error}
    >
      {loading && (
        <div className="absolute w-full">
          <CenteredSpinner className="absolute top-[180px]" />
        </div>
      )}
      {leaderboard?.length ? (
        <table className={cx('w-full', loading && 'opacity-40 pointer-events-none')}>
          <thead className="bg-navbar sticky top-0">
            {table.getHeaderGroups().map((group) => (
              <tr>
                {group.headers.map(({ column, getContext, getSize, id }, index) => (
                  <th
                    className={cx(
                      'text-md font-bold border-x-white border-x-px p-1',
                      column.getCanSort() && 'cursor-pointer hover:bg-navbarHover',
                    )}
                    onClick={() =>
                      column.getCanSort() &&
                      setSortedColumn({
                        columnId: id as LeaderbordColumnId,
                        direction:
                          sortedColumn?.columnId === id && sortedColumn.direction === ListSortDirection.Asc
                            ? ListSortDirection.Desc
                            : ListSortDirection.Asc,
                      })
                    }
                  >
                    <div className="flex-center">
                      {id === sortedColumn?.columnId && sortedColumn?.direction && (
                        <IonIcon
                          name={sortedColumn.direction === ListSortDirection.Asc ? 'caretDown' : 'caretUp'}
                          className="mr-0.5"
                        />
                      )}
                      {flexRender(column.columnDef.header, getContext())}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            <tr>
              <td>
                <Separator absolute />
              </td>
            </tr>

            {table.getRowModel().rows.map((row, index) => {
              table.getRow(row.id);
              return (
                <tr
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={() => history.push(`/${currentFleetId}/drivers/${row.original.driverId}`)}
                >
                  {row.getAllCells().map((cell) => (
                    <td className={cx('p-1 text-md text-center')}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        !loading && <NoDataResponse message={DataResponse.NoFleetData} />
      )}
    </Widget>
  );
};

export default DriverLeaderboardWidget;
