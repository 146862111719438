import { useContext, useState } from 'react';
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { theme } from 'twin.macro';

import NoDataResponse, { DataResponse } from 'atoms/NoDataResponse';
import { Tile, TileIconMap } from 'atoms/WidgetTile';
import WidgetTileGroup from 'atoms/WidgetTile/WidgetTileGroup';
import { defaultDashboardTimePeriod } from 'common/constants';
import { tripStatisticConversionRatios } from 'common/uomConverters';
import { I18nContext } from 'common/useT';
import DonutSegmentChart from 'components/Charts/DonutSegmentChart';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import useSettings from 'components/Settings/useSettings';
import { FleetDriverStats, GetFleetDriverStatsDoc, Period, TripScoreType, TripStatisticType } from 'generated/graphql';
import { DashboardWidget } from 'types/widgets';
import { ecoScoreRatingColorMap, ecoScoreToBandRating, formatCurrency, ratingColorMap, values } from 'utils';
import { useQ } from 'utils/apolloClient';

import DateFilter from '../DateFilter';
import Widget from '../Widget';

const iconMap: TileIconMap<TripStatisticType> = {
  FUEL_CONSUMPTION: 'fuelconsumption',
  FUEL_COST: 'fuelCost',
  FUEL_EFFICIENCY: 'speedAverage',
  IDLE_TIME: 'speedIdle',
  IDLE_TIME_PCT: 'speedIdle',
  IDLE_TIME_HRS: 'speedIdle',
  IDLE_COST: 'fuelCost',
  TOTAL_DISTANCE_DRIVEN: 'distancedriven',
  TOTAL_TIME_DRIVEN: 'timedriven',
  TRIP_COUNT: 'tripcount',
  ECO_SCORE: 'uncategorised',
};

const CssArrow = () => (
  <div className="hidden md:inline absolute left-1/2 bottom-2 w-1/2 h-full">
    <div className="absolute bottom-0 h-[16px] border-dashed border-[0.5px] border-gray-400" />

    <div className="absolute bottom-0 w-[calc(100%-40px)] border-dashed border-[0.5px] border-gray-400" />

    <div className="absolute right-[40px] h-1/2 bottom-0 border-dashed border-[0.5px] border-gray-400" />

    <div className="absolute bottom-1/2 w-[40px] right-0 border-dashed border-[0.5px] border-gray-400"></div>

    <div className="absolute right-0 top-[calc(50%-4px)] h-0 w-0 border-y-[4px] border-y-transparent border-l-[8px] border-l-gray-600"></div>
  </div>
);

const FleetDriverStatsWidget = () => {
  const i18nContext = useContext(I18nContext);
  const { currencyUnit, distanceInMiles, volumeInGallons, idleTimeAsPercentage } = useSettings();
  const currentFleetId = useCurrentFleetId();
  const [period, setPeriod] = useState<Period>(defaultDashboardTimePeriod);

  const { loading, error, data } = useQ(GetFleetDriverStatsDoc, {
    variables: {
      period,
      fleetId: currentFleetId,
    },
  });

  if (error) return <NoDataResponse message={DataResponse.FailedToFetch} />;
  if (!i18nContext) return null;

  const { ecoScores, ecoScoreRatings, drivingStats }: FleetDriverStats = data ?? {
    ecoScoreRatings: { segmentData: [], totalCount: 0 },
    ecoScores: [],
    drivingStats: [],
  };

  const {
    commonTranslations: {
      enums: { tripScoreDescriptionMap, tripStatisticTypeDescriptionMap, dashboardWidgetDescriptionMap },
    },
    tSafe,
  } = i18nContext;

  const conversionRatioMap = tripStatisticConversionRatios({ distanceInMiles, volumeInGallons });
  const idleTimeValue = idleTimeAsPercentage
    ? drivingStats.find((x) => x.type === TripStatisticType.IdleTimePct)?.value
    : drivingStats.find((x) => x.type === TripStatisticType.IdleTimeHrs)?.value;

  const tiles = [
    ...drivingStats.filter(
      (x) => x.type !== TripStatisticType.FuelConsumption && x.type !== TripStatisticType.IdleTimePct,
    ),
  ].map(({ value, type }) => {
    const tripStats: Partial<Record<TripStatisticType, Tile>> = {
      [TripStatisticType.FuelEfficiency]: {
        name: TripStatisticType.FuelEfficiency,
        description: tSafe('components.Dashboard.fleetDriverStatsWidget.fuel-efficiency', {
          defaultValue: 'Fuel efficiency',
        }),
        value: `${Math.round(value * conversionRatioMap[TripStatisticType.FuelEfficiency])} ${
          distanceInMiles ? 'm' : 'k'
        }p${volumeInGallons ? 'g' : 'l'}`,
        className: 'order-0',
        icon: iconMap[type],
      },
      [TripStatisticType.FuelCost]: {
        name: TripStatisticType.FuelCost,
        description: tripStatisticTypeDescriptionMap[TripStatisticType.FuelCost],
        value: formatCurrency(value, currencyUnit, 0),
        className: 'order-1',
        icon: iconMap[type],
      },
      [TripStatisticType.IdleTimeHrs]: {
        name: idleTimeAsPercentage ? TripStatisticType.IdleTimePct : TripStatisticType.IdleTimeHrs,
        description: tSafe('components.Dashboard.fleetDriverStatsWidget.idle-time', {
          defaultValue: 'Idle time',
        }),
        value: `${idleTimeValue?.toFixed(0)} ${idleTimeAsPercentage ? '%' : 'hrs'}`,
        className: 'order-2',
        icon: iconMap[type],
      },
      [TripStatisticType.IdleCost]: {
        name: TripStatisticType.IdleCost,
        description: tripStatisticTypeDescriptionMap[TripStatisticType.IdleCost],
        value: formatCurrency(value, currencyUnit, 0),
        className: 'order-3',
        icon: iconMap[type],
      },
    };

    return tripStats[type]!;
  });

  return (
    <Widget
      title={dashboardWidgetDescriptionMap[DashboardWidget.fleetDriverStatsWidget]}
      filterComponent={<DateFilter selectedPeriod={period} onSelect={(option) => setPeriod(option.value)} />}
      loading={loading}
      error={error}
    >
      <div className="w-full md:grid md:grid-cols-2 lg:flex lg:h-full">
        <div className="h-[440px] md:h-full w-full lg:w-1/3 relative">
          <CssArrow />

          <DonutSegmentChart
            data={
              ecoScoreRatings?.segmentData
                .filter((x) => x.value)
                .map(({ ecoScoreBand, value }) => ({
                  text: ecoScoreBand,
                  value,
                  color: ecoScoreRatingColorMap[ecoScoreBand],
                })) ?? []
            }
            totalCount={ecoScoreRatings?.totalCount ?? 0}
            innerValue={ecoScoreToBandRating(
              ecoScores?.find((x) => x.type === TripScoreType.OverallEcoScore)?.value ?? 0,
            )}
            innerLabel={tSafe('components.Dashboard.fleetDriverStatsWidget.overall-rating', {
              defaultValue: 'Overall rating',
            })}
            innerValueClassName="font-bold"
            innerValueSize={theme`fontSize.7xl`}
            valueType={tSafe('components.Dashboard.fleetDriverStatsWidget.drivers-in-ecos-core-band', {
              defaultValue: 'drivers in Eco Score band',
            })}
            mobileMargin={{ x: 50, y: 50 }}
            desktopMargin={{ x: 20, y: 40 }}
            legendColumnCount={ecoScoreRatings?.segmentData.length}
            startAngle={90}
            useLegend={false}
          />
        </div>

        <div className="grid grid-cols-2 h-[440px] gap-3 md:gap-0 -mt-3 md:mt-0 lg:h-full w-full lg:w-1/3 px-2 md:px-4 pb-3">
          {values(TripScoreType)
            .filter((x) => x !== TripScoreType.OverallEcoScore)
            .map((type) => {
              const value = Math.round(ecoScores?.find((x) => x.type === type)?.value ?? 0);
              const name = tripScoreDescriptionMap[type];
              const pieData = [
                { value, name },
                { value: 100 - value, name: '' },
              ];
              return (
                <ResponsiveContainer key={value} height="100%">
                  <PieChart margin={{ top: 30, bottom: 30 }}>
                    <Pie
                      dataKey="value"
                      data={pieData}
                      innerRadius="70%"
                      outerRadius="100%"
                      startAngle={90}
                      endAngle={-270}
                      isAnimationActive={false}
                      fill={theme`colors.gray-300`}
                    >
                      <Label
                        value={value}
                        position="center"
                        className={'font-bold'}
                        fontSize={theme`fontSize.4xl`}
                        dy={0}
                      />

                      <Label value={name} position="bottom" fontSize={theme`fontSize.sm`} dy={25} />

                      <Cell fill={ratingColorMap[ecoScoreRatingColorMap[ecoScoreToBandRating(value)]]} />
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              );
            })}
        </div>

        <div className="w-full max-w-[56rem] m-auto mr-2 lg:w-1/3">
          <WidgetTileGroup
            tiles={tiles}
            showZeroValues
            borderWidth={2}
            borderRadius={20}
            borderColor={'#9CA3AF'}
            paddingX={20}
            paddingY={15}
            valueClassName="text-lg"
            className="xl:gap-x-4 lg:gap-y-5"
          />
        </div>
      </div>
    </Widget>
  );
};

export default FleetDriverStatsWidget;
