import { useContext } from 'react';
import { I18nContext } from 'common/useT';
import IonIcon from './IonIcon';
import { ApiError } from 'generated/graphql';
import { getError } from 'utils/apolloClient';
import { ApolloError } from '@apollo/client';

export enum DataResponse {
  EntityNotFound,
  NoFleetData,
  NotFound,
  FailedToFetch,
}

export enum SeverityLevel {
  Message,
  Warning,
  Error,
}

export enum NotFoundEntity {
  Device = 'Device',
  Fleet = 'Fleet',
  Driver = 'Driver',
}

const notFoundResponseToEntityMap: Partial<Record<ApiError, NotFoundEntity>> = {
  [ApiError.DeviceNotAvailable]: NotFoundEntity.Device,
  [ApiError.DriverNotAvailable]: NotFoundEntity.Driver,
  [ApiError.FleetNotFound]: NotFoundEntity.Fleet,
};

const getDataResponse = (error?: ApolloError, response?: DataResponse): DataResponse => {
  if (response) return response;

  const errorType = getError(error)?.type;

  if (errorType && notFoundResponseToEntityMap[errorType]) return DataResponse.EntityNotFound;

  return DataResponse.FailedToFetch;
};

const NoDataResponse = ({
  error,
  level = SeverityLevel.Message,
  message,
}: {
  error?: ApolloError;
  level?: SeverityLevel;
  message?: DataResponse;
}) => {
  const i18nContext = useContext(I18nContext);
  if (!i18nContext) return null;

  const {
    commonTranslations: {
      errors: { failed_to_fetch_data_text, not_found_error_text, no_data_fleet_text, entity_not_found_tfn },
    },
  } = i18nContext;

  const errorType = getError(error)?.type;
  const entity = errorType ? notFoundResponseToEntityMap[errorType] ?? '' : '';

  const messageMap: Record<DataResponse, string> = {
    [DataResponse.EntityNotFound]: entity_not_found_tfn(entity),
    [DataResponse.NotFound]: not_found_error_text,
    [DataResponse.NoFleetData]: no_data_fleet_text,
    [DataResponse.FailedToFetch]: failed_to_fetch_data_text,
  };

  return (
    <div className="flex-center h-full items-center">
      {level === SeverityLevel.Error && <IonIcon name="warningOutline" className="text-lg mr-0.5 text-error" />}
      <h3 className="flex-center text-gray-400 text-sm">{messageMap[getDataResponse(error, message)]}</h3>
    </div>
  );
};

export default NoDataResponse;
