import { useContext } from 'react';
import { I18nContext } from 'common/useT';
import Widget from 'components/Dashboard/Widget';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { GetFleetHealthStatusDoc, GetFleetServiceStatusesDoc, VehicleServiceReminderStatus } from 'generated/graphql';
import { DashboardWidget } from 'types/widgets';
import { useQ } from 'utils/apolloClient';
import DonutSegmentChart from 'components/Charts/DonutSegmentChart';
import { theme } from 'twin.macro';
import NoDataResponse, { DataResponse } from 'atoms/NoDataResponse';
import { RatingColor } from 'utils';

const ServiceStatusWidget = () => {
  const i18nContext = useContext(I18nContext);

  const currentFleetId = useCurrentFleetId();
  const { loading, error, data } = useQ(GetFleetHealthStatusDoc, {
    variables: {
      fleetId: currentFleetId,
    },
  });

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { dashboardWidgetDescriptionMap },
    },
    tSafe,
  } = i18nContext;

  const pieData = [
    {
      text: tSafe('components.Dashboard.FleetHealthWidget.healthy', {
        defaultValue: 'Healthy',
      }),
      value: (data?.total ?? 0) - (data?.unhealthy ?? 0),
      color: RatingColor.DarkGreen,
    },
    {
      text: tSafe('components.Dashboard.FleetHealthWidget.require-attention', {
        defaultValue: 'Require Attention',
      }),
      value: data?.unhealthy ?? 0,
      color: RatingColor.DarkRed,
    },
  ];

  return (
    <Widget title={dashboardWidgetDescriptionMap[DashboardWidget.fleetHealthWidget]} loading={loading} error={error}>
      {data?.total ? (
        <DonutSegmentChart data={pieData} totalCount={data.total} valueType="vehicles" legendColumnCount={2} />
      ) : (
        <NoDataResponse message={DataResponse.NoFleetData} />
      )}
    </Widget>
  );
};

export default ServiceStatusWidget;
