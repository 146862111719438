import NoDataResponse, { DataResponse } from 'atoms/NoDataResponse';
import { CenteredSpinner } from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import Widget from 'components/Dashboard/Widget';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { GetMonthlyFuelCostsDoc } from 'generated/graphql';
import { useContext } from 'react';
import { ResponsiveContainer } from 'recharts';
import { DashboardWidget } from 'types/widgets';
import { useQ } from 'utils/apolloClient';
import Chart from './Chart';

const FuelPriceWidget = () => {
  const i18nContext = useContext(I18nContext);
  const { data, previousData, refetch, loading } = useQ(GetMonthlyFuelCostsDoc, {
    variables: {
      fleetId: useCurrentFleetId(),
    },
    notifyOnNetworkStatusChange: true,
  });

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { dashboardWidgetDescriptionMap },
    },
    tSafe,
  } = i18nContext;

  return (
    <Widget title={dashboardWidgetDescriptionMap[DashboardWidget.fuelCostsWidget]}>
      {data?.length ? (
        <>
          <ResponsiveContainer width="100%" height="100%">
            <Chart data={data} />
          </ResponsiveContainer>
        </>
      ) : loading ? (
        <CenteredSpinner />
      ) : (
        <NoDataResponse message={DataResponse.NoFleetData} />
      )}
    </Widget>
  );
};

export default FuelPriceWidget;
