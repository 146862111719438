import { User } from '@auth0/auth0-react';
import {
  AggregateDashboardLight,
  AggregateDtc,
  BatteryNotificationType,
  BatterySohState,
  CurrencyUnit,
  Device,
  DeviceConnectionStatus,
  DistanceUnit,
  GetEntityListQuery,
  GetUserDoc,
  GetUsersDoc,
  ListColumnId,
  ReportPeriod,
  ReportSchedule,
  ReportType,
  SavedListView,
  UserNotificationSettings,
  UserRole,
  VehicleServiceReminderStatus,
  VolumeUnit,
  WeeklyReport,
} from 'generated/graphql';
import { PropsWithChildren } from 'react';
import { Cell } from 'react-table';
import { Result } from 'types';
import { SerializedGridLayout } from 'types/gridLayout';
import { DashboardWidget } from 'types/widgets';

export type DashboardWidgetSettings = Record<
  DashboardWidget,
  { layout: SerializedGridLayout[string]; hiddenItems: string[] }
>;
export type DriverPageWidgetSettings = Record<string, { layout: SerializedGridLayout[string] }>;
export type VehiclePageWidgetSettings = Record<string, { layout: SerializedGridLayout[string] }>;

export type VehiclePageSettings = {
  collapsedWidgets: string[];
  widgetLayout: VehiclePageWidgetSettings;
};
export type DriverPageSettings = {
  collapsedWidgets: string[];
  widgetLayout: DriverPageWidgetSettings;
};

export type ListViewSettings = {
  savedListViews: Record<string, SavedListView>;
  defaultListView: SavedListView;
  activeListView: string;
};

export type UserListSettings = { hiddenColumns: string[] };

export type Settings = Omit<
  NonNullable<Result<typeof GetUserDoc>>['settings'],
  | 'dashboardWidgetSettings'
  | 'vehiclePageSettings'
  | 'driverPageSettings'
  | 'listViewSettings'
  | 'currencyUnit'
  | 'notificationSettings'
> & {
  dashboardWidgetSettings: DashboardWidgetSettings;
  vehiclePageSettings: VehiclePageSettings;
  driverPageSettings: DriverPageSettings;
  listViewSettings: ListViewSettings;
  userListSettings: UserListSettings;
  currencyUnit: CurrencyUnit;
  notificationSettings: UserNotificationSettings;
};

export type ListItem = GetEntityListQuery['aggregatedListDetails']['data'][number];

export type ListCell<T> = PropsWithChildren<Cell<ListItem, T>>;

export type ReportingColumnId =
  | 'id'
  | 'type'
  | 'fleetIds'
  | 'dateRange'
  | 'driverName'
  | 'licencePlate'
  | 'schedule'
  | 'createdAt'
  | 'reportSentAt';

export type ReportingListRow = {
  id: string;
  type: ReportType;
  fleetIds: string[];
  currencyUnit: CurrencyUnit;
  dateRange?: ReportPeriod;
  dateFrom?: string | null;
  dateTo?: string | null;
  dieselPrice: number;
  driverName?: string;
  dayOfWeek: WeeklyReport;
  dayOfMonth: number;
  distanceUnit: DistanceUnit;
  idleTime: string;
  licencePlate?: string;
  petrolPrice: number;
  schedule: ReportSchedule;
  createdAt: string;
  reportSentAt?: string | null;
  volumeUnit?: VolumeUnit;
};

export type AggregateListRow = {
  licencePlate?: string;
  make?: string;
  model?: string;
  driverName?: string;
  fleets?: string[];
  device?: Device;
  deviceStatus?: DeviceConnectionStatus | null;
  ecoScore?: number | null;
  activeDtcs?: AggregateDtc[] | null;
  batteryHealth?: BatterySohState | null;
  batteryCharge?: number | null;
  batteryStatus?: BatteryNotificationType | null;
  dashboardLights?: AggregateDashboardLight[] | null;
  distanceDriven?: number | null;
  drivingTime?: number;
  dtcStored?: number;
  dtcPending?: number;
  dtcPermanent?: number;
  idleTime?: number | null;
  fuelEfficiency?: number | null;
  fuel?: string | null;
  fuelConsumption?: number | null;
  milStatus?: boolean | null;
  longIdlingEventCount?: number | null;
  tripCount?: number | null;
  impactCount?: number | null;
  unassigned?: number | null;
  vehicleId?: string | null;
  driverId?: string | null;
  serviceStatus?: VehicleServiceReminderStatus | null;
};

export type UserListItem = Result<typeof GetUsersDoc>[number];

export type UserListCell<T> = PropsWithChildren<Cell<UserListItem, T>>;

export type UserActionProps = Partial<Pick<User, 'id' | 'driverId' | 'email' | 'name' | 'role'>>;

export type UserListRow = {
  name?: string | null;
  role?: UserRole | null;
  driver?: { id?: string | null };
  active?: boolean | null;
  email: string;
  fleets?: string[];
  actions: UserActionProps;
};

export enum ListData {
  Default,
  IdlingPerformance,
}

export interface ColumnsState {
  disabledColumns: ListColumnId[];
  enforcedColumns: ListColumnId[];
}

export enum Month {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}
