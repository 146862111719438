import React from 'react';

import { ReactComponent as driverOutline } from 'atoms/Icon/generic/driver.svg';
import { cx } from 'utils';

import { ReactComponent as addCircleOutline } from './icons/add-circle-outline.svg';
import { ReactComponent as alertCircleOutline } from './icons/alert-circle-outline.svg';
import { ReactComponent as alertOutline } from './icons/alert-outline.svg';
import { ReactComponent as arrowDown } from './icons/arrow-down-outline.svg';
import { ReactComponent as arrowUp } from './icons/arrow-up-outline.svg';
import { ReactComponent as banOutline } from './icons/ban-outline.svg';
import { ReactComponent as bulbOutline } from './icons/bulb-outline.svg';
import { ReactComponent as calendarOutline } from './icons/calendar-outline.svg';
import { ReactComponent as caretDown } from './icons/caret-down-sharp.svg';
import { ReactComponent as caretUp } from './icons/caret-up-sharp.svg';
import { ReactComponent as carSportOutline } from './icons/car-sport-outline.svg';
import { ReactComponent as carOutline } from './icons/car.svg';
import { ReactComponent as cellularOutline } from './icons/cellular-outline.svg';
import { ReactComponent as checkmarkCircleOutline } from './icons/checkmark-circle-outline.svg';
import { ReactComponent as checkmarkCircleSharp } from './icons/checkmark-circle-sharp.svg';
import { ReactComponent as chevronDownOutline } from './icons/chevron-down-outline.svg';
import { ReactComponent as chevronForwardOutline } from './icons/chevron-forward-outline.svg';
import { ReactComponent as chevronUpOutline } from './icons/chevron-up-outline.svg';
import { ReactComponent as clipboardOutline } from './icons/clipboard-outline.svg';
import { ReactComponent as clipboardSharp } from './icons/clipboard-sharp.svg';
import { ReactComponent as closeCircleOutline } from './icons/close-circle-outline.svg';
import { ReactComponent as closeOutline } from './icons/close-outline.svg';
import { ReactComponent as cloudUploadOutline } from './icons/cloud-upload-outline.svg';
import { ReactComponent as contractOutline } from './icons/contract-outline.svg';
import { ReactComponent as copyOutline } from './icons/copy-outline.svg';
import { ReactComponent as createOutline } from './icons/create-outline.svg';
import { ReactComponent as downloadOutline } from './icons/download-outline.svg';
import { ReactComponent as expandOutline } from './icons/expand-outline.svg';
import { ReactComponent as eyeOffOutline } from './icons/eye-off-outline.svg';
import { ReactComponent as eyeOutline } from './icons/eye-outline.svg';
import { ReactComponent as fitness } from './icons/fitness.svg';
import { ReactComponent as flashOutline } from './icons/flash-outline.svg';
import { ReactComponent as helpCircleOutline } from './icons/help-circle-outline.svg';
import { ReactComponent as helpOutline } from './icons/help-outline.svg';
import { ReactComponent as infiniteOutline } from './icons/infinite-outline.svg';
import { ReactComponent as locateOutline } from './icons/locate-outline.svg';
import { ReactComponent as locationOutline } from './icons/location-outline.svg';
import { ReactComponent as logOutOutline } from './icons/log-out-outline.svg';
import { ReactComponent as menuOutline } from './icons/menu-outline.svg';
import { ReactComponent as notificationsOutline } from './icons/notifications-outline.svg';
import { ReactComponent as notifications } from './icons/notifications.svg';
import { ReactComponent as pencilOutline } from './icons/pencil-outline.svg';
import { ReactComponent as pencil } from './icons/pencil.svg';
import { ReactComponent as peopleOutline } from './icons/people-outline.svg';
import { ReactComponent as personCircleOutline } from './icons/person-circle-outline.svg';
import { ReactComponent as personOutline } from './icons/person-outline.svg';
import { ReactComponent as refreshOutline } from './icons/refresh-outline.svg';
import { ReactComponent as searchOutline } from './icons/search.svg';
import { ReactComponent as settingsOutline } from './icons/settings-outline.svg';
import { ReactComponent as syncOutline } from './icons/sync-outline.svg';
import { ReactComponent as timeOutline } from './icons/time-outline.svg';
import { ReactComponent as timerOutline } from './icons/timer-outline.svg';
import { ReactComponent as trashOutline } from './icons/trash-outline.svg';
import { ReactComponent as trendingDownOutline } from './icons/trending-down-outline.svg';
import { ReactComponent as unlinkOutline } from './icons/unlink-outline.svg';
import { ReactComponent as warningOutline } from './icons/warning-outline.svg';

// List of icons: https://ionic.io/ionicons

// add only required icons
const loadedIcons = {
  arrowUp,
  arrowDown,
  settingsOutline,
  banOutline,
  bulbOutline,
  calendarOutline,
  carOutline,
  checkmarkCircleOutline,
  checkmarkCircleSharp,
  caretDown,
  caretUp,
  chevronDownOutline,
  chevronUpOutline,
  chevronForwardOutline,
  clipboardOutline,
  clipboardSharp,
  copyOutline,
  eyeOutline,
  eyeOffOutline,
  trashOutline,
  notificationsOutline,
  notifications,
  helpCircleOutline,
  logOutOutline,
  addCircleOutline,
  flashOutline,
  infiniteOutline,
  closeOutline,
  alertOutline,
  helpOutline,
  trendingDownOutline,
  pencil,
  pencilOutline,
  personCircleOutline,
  searchOutline,
  timeOutline,
  timerOutline,
  locationOutline,
  carSportOutline,
  locateOutline,
  unlinkOutline,
  cellularOutline,
  personOutline,
  syncOutline,
  createOutline,
  driverOutline,
  warningOutline,
  fitness,
  expandOutline,
  contractOutline,
  cloudUploadOutline,
  downloadOutline,
  alertCircleOutline,
  closeCircleOutline,
  peopleOutline,
  refreshOutline,
  menuOutline,
};

export type IonIconName = keyof typeof loadedIcons;

const fillIcons = new Set<IonIconName>([
  'helpOutline',
  'carSportOutline',
  'createOutline',
  'alertCircleOutline',
  'warningOutline',
]);

interface IonIconProps {
  name: IonIconName;
  fillCurrent?: boolean;
}

const IonIcon = ({
  name,
  className,
  fillCurrent,
  ...props
}: IonIconProps &
  (
    | ({ noWrapper: true } & Omit<React.SVGProps<SVGSVGElement>, 'ref'>)
    | ({ noWrapper?: false } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>)
  )) => {
  const Icon = loadedIcons[name];

  if (props.noWrapper) {
    const { noWrapper: _, ...rest } = props;

    return (
      <Icon
        width="100%"
        height="100%"
        className={cx(
          'children:!stroke-current',
          (fillCurrent ?? fillIcons.has(name)) && 'children:!fill-current',
          className,
        )}
        title=""
        {...rest}
      />
    );
  }

  return (
    <span
      className={cx(
        !className && 'flex-center',
        (fillCurrent ?? fillIcons.has(name)) && 'children:!fill-current',
        className,
      )}
      {...props}
    >
      <Icon width="1em" height="1em" className="children:!stroke-current" title="" />
    </span>
  );
};

export default IonIcon;
