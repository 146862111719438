import { ApolloError } from '@apollo/client';
import React, { ReactNode, useContext, useState } from 'react';
import { useRecoilState } from 'recoil';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import NoDataResponse, { DataResponse, SeverityLevel } from 'atoms/NoDataResponse';
import Separator from 'atoms/Separator';
import { CenteredSpinner } from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { driverPageCollapsedWidgetsState } from 'components/Driver/Detail/state';
import { cx } from 'utils';

import { vehiclePageCollapsedWidgetsState } from '../../Vehicle/Detail/state';
import { useDeleteWidget } from './hooks';

export const draggableHandleClass = 'draggableHandle';

export const WidgetContext = React.createContext({
  isEditMode: false,
  setIsEditMode: (() => {}) as (value: boolean) => void,
  widgetKey: '',
});

export const WidgetContextProvider = ({ widgetKey, children }: { widgetKey: string; children: ReactNode }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <WidgetContext.Provider value={{ isEditMode: isEditMode, setIsEditMode, widgetKey }}>
      {children}
    </WidgetContext.Provider>
  );
};

export const WidgetWrapper =
  (title: string, filterComponent: JSX.Element): React.FC =>
  ({ children }) =>
    (
      <Widget title={title} filterComponent={filterComponent}>
        {children}
      </Widget>
    );

export enum WidgetPageType {
  Driver,
  Vehicle,
}

interface WidgetProps {
  title: string;
  collapsible?: boolean;
  scrollable?: boolean;
  warning?: boolean;
  filterComponent?: React.ReactElement | false;
  children?: React.ReactNode;
  className?: string;
  loading?: boolean;
  error?: ApolloError;
  page?: WidgetPageType;
}

const Widget = ({
  title,
  collapsible,
  scrollable,
  warning,
  filterComponent,
  children,
  className,
  loading,
  error,
  page,
}: WidgetProps) => {
  const i18nContext = useContext(I18nContext);
  const deleteWidget = useDeleteWidget();
  const { isEditMode, setIsEditMode, widgetKey } = useContext(WidgetContext);
  const [vehiclePageCollapsedWidgets, setVehiclePageCollapsedWidgets] = useRecoilState(
    vehiclePageCollapsedWidgetsState,
  );
  const [driverPageCollapsedWidgets, setDriverPageCollapsedWidgets] = useRecoilState(driverPageCollapsedWidgetsState);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;
  const collapsedWidgets = page === WidgetPageType.Driver ? driverPageCollapsedWidgets : vehiclePageCollapsedWidgets;
  const isCollapsed = collapsedWidgets.includes(widgetKey);

  const DataHandler = ({ children }: { children: JSX.Element }) => {
    if (loading) return <CenteredSpinner className="text-[8px]" />;
    if (error)
      return (
        <div className="flex-center w-full h-full items-center -mt-4">
          <NoDataResponse level={SeverityLevel.Error} message={DataResponse.FailedToFetch} />
        </div>
      );
    return children;
  };

  const collapsedWidgetsState = isCollapsed
    ? collapsedWidgets.filter((x) => x !== widgetKey)
    : [widgetKey, ...collapsedWidgets];

  return (
    <div
      className={cx(
        'flex flex-col w-full min-w-10 h-full min-h-5 bg-white rounded-4 shadow-card',
        className,
        !scrollable || isCollapsed ? 'overflow-hidden' : 'overflow-y-auto',
        error && 'opacity-70',
      )}
      role="grid"
      data-testid="widget"
    >
      <div className="flex items-center px-2 z-20">
        {collapsible ? (
          <>
            <div className="p-1">
              <Button
                onClick={() => {
                  if (page === WidgetPageType.Driver) {
                    setDriverPageCollapsedWidgets(collapsedWidgetsState);
                  }
                  if (page === WidgetPageType.Vehicle) {
                    setVehiclePageCollapsedWidgets(collapsedWidgetsState);
                  }
                }}
                className="-ml-2 h-2 w-2 text-lg ui-button"
                aria-label={tSafe('components.Dashboard.Widget.collapse-widget', { defaultValue: 'Collapse widget' })}
              >
                <IonIcon name={isCollapsed ? 'chevronForwardOutline' : 'chevronDownOutline'} />
              </Button>
            </div>

            <h3 className={`flex-auto py-1.5 text-md font-bold cursor-move ${draggableHandleClass}`}>{title}</h3>

            {warning && (
              <div className="ml-auto">
                <svg width="10" viewBox="0 0 4 4" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="red" />
                </svg>
              </div>
            )}
          </>
        ) : (
          <>
            <Button
              className={cx('mr-1 text-error text-lg ui-button', !isEditMode && 'hidden')}
              onClick={() => deleteWidget(widgetKey)}
              aria-label={tSafe('components.Dashboard.Widget.delete-widget', { defaultValue: 'Delete widget' })}
            >
              <IonIcon name="trashOutline" />
            </Button>

            <h3 className={`flex-1 py-1.5 text-md font-bold cursor-move ${draggableHandleClass}`}>{title}</h3>

            {filterComponent}

            <Button
              className={cx('ml-1 text-lg p-0.5 ui-button rounded-4', !isEditMode && `opacity-40`)}
              onClick={() => setIsEditMode(!isEditMode)}
              aria-label={tSafe('components.Dashboard.Widget.configure-widget', {
                defaultValue: 'Configure widget',
              })}
            >
              <IonIcon name="settingsOutline" />
            </Button>
          </>
        )}
      </div>

      <Separator />

      <DataHandler>
        <div className={cx('flex-1', !collapsible && 'overflow-y-auto')}>{children}</div>
      </DataHandler>
    </div>
  );
};

export default Widget;
