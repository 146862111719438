import { useEffect, useState } from 'react';

import { ConditionalWrapper } from 'atoms/ConditionalWrapper';
import NoDataResponse, { DataResponse } from 'atoms/NoDataResponse';
import Tooltip from 'atoms/Tooltip';
import WidgetTile, { Tile } from 'atoms/WidgetTile';
import { cx } from 'utils';

interface WidgetTileGroupProps<T extends Tile> {
  tiles: T[];
  onSelected?: (selected: T['name']) => void;
  valueClassName?: string;
  defaultIconClassName?: string;
  className?: string;
  initialSelection?: T['name'];
  showZeroValues?: boolean;
  count?: boolean;
  borderWidth?: number;
  borderRadius?: number;
  borderColor?: string;
  paddingX?: number;
  paddingY?: number;
}

const WidgetTileGroup = <T extends Tile & { tooltip?: string }>({
  tiles,
  onSelected,
  valueClassName,
  defaultIconClassName,
  className,
  initialSelection,
  showZeroValues,
  count,
  borderWidth,
  borderRadius,
  borderColor,
  paddingX,
  paddingY,
}: WidgetTileGroupProps<T>) => {
  const [selected, setSelected] = useState(initialSelection);

  useEffect(() => {
    selected && onSelected?.(selected);
  }, [onSelected, selected]);

  return tiles.length ? (
    <div
      className={cx(
        'grid gap-1 grid-rows-1 p-1 auto-rows-fr grid-cols-[repeat(auto-fill,minmax(23rem,1fr))]',
        className,
      )}
    >
      {tiles.map(({ name, description, value, className, tooltip, icon: iconName, onClick }) => (
        <div className={className}>
          <ConditionalWrapper
            condition={!!tooltip}
            wrapper={({ children }: { children: JSX.Element }) => <Tooltip text={tooltip}>{children}</Tooltip>}
          >
            <WidgetTile
              key={name}
              name={name}
              description={description}
              value={value}
              icon={iconName}
              selected={selected === name}
              onClick={() => {
                onClick?.();
                onSelected && setSelected(name);
              }}
              valueClassName={valueClassName}
              defaultIconClassName={defaultIconClassName}
              showZeroValues={showZeroValues}
              count={count}
              borderWidth={borderWidth}
              borderRadius={borderRadius}
              borderColor={borderColor}
              paddingX={paddingX}
              paddingY={paddingY}
            />
          </ConditionalWrapper>
        </div>
      ))}
    </div>
  ) : (
    <NoDataResponse message={DataResponse.NoFleetData} />
  );
};

export default WidgetTileGroup;
