import Preferences from 'components/Settings/Preferences';
import UserDetails from 'components/Settings/UserDetails';
import UserNotificationSettings from 'components/Settings/UserNotificationSettings';

const Settings = () => (
  <div className="max-w-[600px] ml-2 flex flex-col">
    <div className="flex py-2 space-x-2 children:w-1/2">
      <UserDetails />

      <Preferences />
    </div>

    <UserNotificationSettings />
  </div>
);

export default Settings;
