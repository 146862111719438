export enum DashboardWidget {
  dashboardLightEventCountWidget = 'dashboardLightEventCountWidget',
  deviceStatusWidget = 'deviceStatusWidget',
  driverLeaderboardWidget = 'driverLeaderboardWidget',
  serviceStatusWidget = 'serviceStatusWidget',
  fleetDriverStatsWidget = 'fleetDriverStatsWidget',
  fleetHealthWidget = 'fleetHealthWidget',
  fuelCostsWidget = 'fuelCostsWidget',
  utilisationWidget = 'utilisationWidget',
}

export enum VehicleDetailPageWidget {
  vehicleStatusWidget = 'vehicleStatusWidget',
  vehicleLastKnownLocationWidget = 'vehicleLastKnownLocationWidget',
  geofencesWidget = 'geofencesWidget',
  vehicleLevelsWidget = 'vehicleLevelsWidget',
  tyrePressureWidget = 'tyrePressureWidget',
  dashboardLightsWidget = 'dashboardLightsWidget',
  deviceInfoWidget = 'deviceInfoWidget',
}

export enum DriverDetailPageWidget {
  ecoScoreWidget = 'ecoScoreWidget',
  drivingStatsWidget = 'drivingStatsWidget',
  vehicleInfoWidget = 'vehicleInfoWidget',
  driverLastKnownLocationWidget = 'driverLastKnownLocationWidget',
}
