import { I18nContext } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import { format } from 'date-fns';
import { CurrencyUnit } from 'generated/graphql';
import { useContext } from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { NameType } from 'recharts/types/component/DefaultTooltipContent';
import { RatingColor, formatCurrency, ratingColorMap } from 'utils';

export interface ChartData {
  date: Date;
  idleCost: number;
  fuelCost: number;
  fuelPrice: number;
}

const CustomLegend = (props: any) => {
  const { payload } = props;

  return (
    <div className="-mt-2.5 flex justify-center space-x-2 text-black font-semibold">
      {payload.map((entry: any, index: number) => (
        <div key={`legend-item-${index}`} className="flex items-center space-x-1">
          {entry.dataKey === 'fuelPrice' ? (
            <svg width="20" height="10">
              <line x1="0" y1="5" x2="20" y2="5" stroke="black" strokeWidth="2" />
            </svg>
          ) : (
            <div className="w-[10px] h-[10px] rounded-sm" style={{ backgroundColor: entry.color }} />
          )}
          <span className="text-sm text-dark-gray">{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

const CustomTooltip = ({
  active,
  payload,
  label,
  currencyUnit,
}: TooltipProps<number, NameType> & { currencyUnit: CurrencyUnit }) => {
  if (!active || !payload || !payload.length) return null;

  return (
    <div className="bg-white border border-gray-300 p-3 shadow-md rounded-md text-black">
      <p className="font-bold mb-2">{format(new Date(label), 'MMMM yyyy')}</p>
      {payload.map((entry) => {
        return (
          <div key={entry.name} className="flex items-center space-x-2 h-3">
            <div className="font-semibold">
              {entry.dataKey === 'fuelPrice' ? (
                <svg width="10" height="10">
                  <line x1="0" y1="5" x2="20" y2="5" stroke="black" strokeWidth="2" />
                </svg>
              ) : (
                <div className="w-[10px] h-[10px] rounded-sm" style={{ backgroundColor: entry.color }} />
              )}
            </div>
            <div className="font-semibold">{entry.name}:</div>
            <span>{formatCurrency(Number(entry.value), currencyUnit, entry.dataKey === 'fuelPrice' ? 2 : 0)}</span>
          </div>
        );
      })}
    </div>
  );
};

const Chart = ({ data }: { data: ChartData[] }) => {
  const i18nContext = useContext(I18nContext);
  const { currencyUnit } = useSettings();

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div className="h-[340px] w-full">
      <ResponsiveContainer>
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 0,
            left: 0,
            bottom: -10,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" vertical={false} />
          <XAxis
            dataKey="date"
            tick={{ fontWeight: 'semibold', fill: 'black' }}
            tickFormatter={(value) => (Date.parse(value) ? format(new Date(value), 'MMM') : '')}
          />
          <Tooltip content={<CustomTooltip currencyUnit={currencyUnit} />} />
          <Legend content={<CustomLegend />} verticalAlign="top" align="center" height={26} />

          <Bar
            dataKey="idleCost"
            stackId="cost"
            barSize={100}
            fill={ratingColorMap[RatingColor.DarkRed]}
            yAxisId="left"
            name={tSafe('components.Dashboard.FuelCostWidget.idle-cost', {
              defaultValue: 'Idle Cost',
            })}
          />
          <Bar
            radius={[6, 6, 0, 0]}
            dataKey="fuelCost"
            stackId="cost"
            barSize={100}
            fill="#ffd100"
            yAxisId="left"
            name={tSafe('components.Dashboard.FuelCostWidget.total-spend', {
              defaultValue: 'Total spend',
            })}
          />

          <Line
            dot={{ fill: 'black' }}
            strokeWidth={2}
            type="monotone"
            dataKey="fuelPrice"
            stroke="black"
            yAxisId="right"
            name={tSafe('components.Dashboard.FuelCostWidget.fuel-price', {
              defaultValue: 'Fuel price',
            })}
          />

          <YAxis
            tickLine={false}
            yAxisId="left"
            axisLine={false}
            tick={{ fontWeight: 'semibold' }}
            tickFormatter={(value) => `${formatCurrency(Number(value), currencyUnit, 0)}`}
            domain={[0, 'dataMax + 50']}
            tickCount={6}
          />

          <YAxis
            tickLine={false}
            yAxisId="right"
            orientation="right"
            axisLine={false}
            tick={{ fontWeight: 'semibold' }}
            tickFormatter={(value) => `${formatCurrency(Number(value), currencyUnit)}`}
            domain={['dataMin - 0.1', 'dataMax + 0.2']}
            tickCount={6}
            width={50}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
