import { Redirect, Route, Switch } from 'react-router-dom';

import Dashboard from 'components/Dashboard';
import DriverDetail from 'components/Driver/Detail';
import FleetManagementPage from 'components/Fleet/FleetManagementPage';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import ListPage from 'components/List/ListPage';
import Live from 'components/Live';
import Reporting from 'components/Reporting';
import Settings from 'components/Settings';
import useTenant from 'components/Tenant/useTenant';
import UserManagementPage from 'components/User/UserManagementPage';
import useRole from 'components/User/useRole';
import useUser from 'components/User/useUser';
import VehicleDetail from 'components/Vehicle/Detail';
import IdlingPerformance from 'components/List/IdlingPerformance';
import FuelPriceSettings from 'components/Settings/Admin/FuelPrices';

const Routes = () => {
  const { driverId } = useUser();
  const { isSuperAdmin, isAdmin, isViewer, isDriver } = useRole();
  const currentFleetId = useCurrentFleetId({ optional: true });
  const tenant = useTenant();

  return (
    <Switch>
      <Route exact path="/:fleetId">
        {isViewer ? (
          <Dashboard />
        ) : isDriver ? (
          <Redirect to={`/${currentFleetId}/drivers/${driverId}`} />
        ) : (
          <div className="h-full flex-center">
            <h1 className="text-2xl">You do not have access to the portal</h1>
          </div>
        )}
      </Route>

      <Route path="/:fleetId/vehicles/:vehicleId">
        <VehicleDetail />
      </Route>

      <Route path="/:fleetId/drivers/:driverId">
        <DriverDetail />
      </Route>

      <Route path="/:fleetId?/settings">
        <Settings />
      </Route>

      {isViewer && (
        <>
          <Route path="/:fleetId/live">
            <Live />
          </Route>

          <Route path="/:fleetId/list-view">
            <ListPage />
          </Route>

          <Route path="/:fleetId/idling-performance">
            <IdlingPerformance />
          </Route>

          <Route path="/:fleetId/fleets">
            <FleetManagementPage />
          </Route>

          <Route path="/:fleetId/users">
            <UserManagementPage />
          </Route>

          {(tenant?.reportingEnabled || isSuperAdmin) && (
            <Route path="/:fleetId?/reporting">
              <Reporting />
            </Route>
          )}
          {isAdmin && (
            <Route path="/:fleetId/edit-fuel-prices">
              <FuelPriceSettings />
            </Route>
          )}
        </>
      )}
    </Switch>
  );
};

export default Routes;
