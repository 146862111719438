import { useContext } from 'react';
import { I18nContext } from 'common/useT';
import Widget from 'components/Dashboard/Widget';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { GetFleetServiceStatusesDoc, VehicleServiceReminderStatus } from 'generated/graphql';
import { DashboardWidget } from 'types/widgets';
import { useQ } from 'utils/apolloClient';
import DonutSegmentChart from 'components/Charts/DonutSegmentChart';
import { theme } from 'twin.macro';
import NoDataResponse, { DataResponse } from 'atoms/NoDataResponse';
import { RatingColor } from 'utils';

const colorMap: Record<VehicleServiceReminderStatus, RatingColor> = {
  [VehicleServiceReminderStatus.Off]: RatingColor.DarkGreen,
  [VehicleServiceReminderStatus.Due]: RatingColor.Orange,
  [VehicleServiceReminderStatus.Overdue]: RatingColor.DarkRed,
};

const ServiceStatusWidget = () => {
  const i18nContext = useContext(I18nContext);

  const currentFleetId = useCurrentFleetId();
  const { loading, error, data } = useQ(GetFleetServiceStatusesDoc, {
    variables: {
      fleetId: currentFleetId,
    },
  });

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { dashboardWidgetDescriptionMap, serviceReminderStatusDescriptionMap },
    },
    tSafe,
  } = i18nContext;

  const pieData =
    data?.serviceStatuses?.map(({ serviceStatus, count }) => {
      return {
        text: serviceReminderStatusDescriptionMap[serviceStatus],
        value: count ?? 0,
        color: colorMap[serviceStatus as VehicleServiceReminderStatus],
        link: `${currentFleetId}/list-view?serviceStatus=${serviceStatus}`,
      };
    }) ?? [];

  return (
    <Widget title={dashboardWidgetDescriptionMap[DashboardWidget.serviceStatusWidget]} loading={loading} error={error}>
      {data?.serviceStatuses.length ? (
        <DonutSegmentChart
          data={pieData}
          totalCount={pieData.reduce((acc, curr) => acc + curr.value, 0) || 0}
          valueType={tSafe('common.domain.vehicle.fields.vehicleService', {
            defaultValue: 'vehicle service',
          })}
          legendColumnCount={3}
        />
      ) : (
        <NoDataResponse message={DataResponse.NoFleetData} />
      )}
    </Widget>
  );
};

export default ServiceStatusWidget;
