import { useMutation } from '@apollo/client';
import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { CenteredSpinner } from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { FuelPriceDate, FuelType, GetFuelPricesDoc, UpdateFuelPricesDoc } from 'generated/graphql';
import { useContext, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { Month } from 'types/settings';
import { cx, groupByMonth } from 'utils';
import { useQ } from 'utils/apolloClient';
import useSettings from '../../useSettings';
import EditableInput from './EditableInput';
import FuelRow, { FuelField, FuelPriceAction } from './FuelRow';

const StyledCalendar = styled.div`
  font-size: 12px;
  position: absolute;

  .react-datepicker__current-month {
    font-size: 16px;
    padding: 2px 0 6px;
  }
  .react-datepicker__navigation {
    margin: 8px 0;
  }
  .react-datepicker__day {
    width: 4rem;
    height: 4rem;
    padding-top: 8px;
  }
  .react-datepicker__day-name {
    width: 4rem;
  }
`;

export type FuelPriceMonth = {
  year: number;
  month: Month;
  items: FuelPriceDate[];
};

const FuelPriceSettings = () => {
  const i18nContext = useContext(I18nContext);
  const { locale } = useSettings();

  const { data: fuelPrices, loading: loadingFuelPrices, previousData } = useQ(GetFuelPricesDoc);
  const [updateFuelPrices, { data: updatedFuelPrices, loading: updatingFuelPrices }] = useMutation(
    UpdateFuelPricesDoc,
    {
      update: (cache) => {
        cache.writeQuery({
          query: GetFuelPricesDoc,
          data: { fuelPrices: updatedFuelPrices?.updateFuelPrices ?? [] },
        });
      },
    },
  );

  const fuelPriceData = useMemo(
    () => updatedFuelPrices?.updateFuelPrices ?? fuelPrices,
    [updatedFuelPrices, fuelPrices],
  );

  const { dieselPrice: currentDieselPrice, petrolPrice: currentPetrolPrice } = (fuelPriceData &&
    groupByMonth(fuelPriceData)
      .sort((a, b) => new Date(b.year, b.month).getTime() - new Date(a.year, a.month).getTime())[0]
      ?.items.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      .at(0)) ?? { date: new Date(), dieselPrice: undefined, petrolPrice: undefined };

  const [addingNewDate, setAddingNewDate] = useState<boolean>();
  const [newDate, setNewDate] = useState<Date | null>(null);
  const [newDieselPrice, setNewDieselPrice] = useState<number | null | undefined>(currentDieselPrice);
  const [newPetrolPrice, setNewPetrolPrice] = useState<number | null | undefined>(currentPetrolPrice);
  const [editedRow, setEditedRow] = useState<{ date: string; field?: FuelField } | undefined | null>();

  const getDateString = (date: Date | string): string => new Date(date).toISOString().split('T')[0];

  const handleFuelPriceChange = (row: FuelPriceDate, action: FuelPriceAction) => {
    const rowDate = getDateString(row.date);
    const existingEntry = fuelPriceData?.some((price) => getDateString(price.date) === rowDate);

    const actionToUpdaterMap = {
      [FuelPriceAction.Create]: (prices: FuelPriceDate[], row: FuelPriceDate) => [...prices, row],
      [FuelPriceAction.Update]: (prices: FuelPriceDate[], row: FuelPriceDate) =>
        prices
          .map((price) => (getDateString(price.date) === rowDate ? { ...row } : price))
          .filter((price) => price.dieselPrice || price.petrolPrice),
      [FuelPriceAction.Delete]: (prices: FuelPriceDate[]) =>
        prices.filter((price) => getDateString(price.date) !== rowDate),
    };

    const actionAccessor = action === FuelPriceAction.Create && existingEntry ? FuelPriceAction.Update : action;
    const prices = fuelPriceData ?? [];

    const updatedFuelPrices = actionToUpdaterMap[actionAccessor](prices, {
      date: getDateString(row.date),
      dieselPrice: row.dieselPrice || undefined,
      petrolPrice: row.petrolPrice || undefined,
    });

    updateFuelPrices({
      variables: { fuelPrices: updatedFuelPrices.map(({ __typename, ...rest }) => rest) },
      optimisticResponse: {
        updateFuelPrices: updatedFuelPrices,
      },
    });

    setNewDate(new Date(getDateString(row.date)));
    setAddingNewDate(false);
    setEditedRow(undefined);
  };

  if (!i18nContext) return null;
  const {
    tSafe,
    commonTranslations: {
      enums: { fuelTypeDescriptionMap },
      forms: {
        buttons: { cancel_text, save_text },
      },
    },
  } = i18nContext;

  const FuelPriceHeaders = () => (
    <li className="-mt-3 flex group items-center text-md font-semibold border-b border-gray-300 p-1 rounded-4">
      <h2 className="flex-[0_0_160px]"></h2>
      <div className="grow -ml-2  w-8 flex-center">{fuelTypeDescriptionMap[FuelType.Diesel]}</div>
      <div className="grow w-8 flex-center">{fuelTypeDescriptionMap[FuelType.Petrol]}</div>
      <div className="w-8 flex-end space-x-2" />
    </li>
  );

  const FuelPriceList = ({ groupedData }: { groupedData: FuelPriceMonth[] }) => {
    return (
      <div className={cx(updatingFuelPrices && 'opacity-60 pointer-events-none')}>
        {groupedData.map(({ year, month, items }, i) => (
          <div key={`${year}-${month}`}>
            <h2 className="text-xl font-bold mt-3 -mb-2">
              {Month[month]} {year}
            </h2>
            <ul>
              <FuelPriceHeaders />

              {items
                .sort((a, b) => new Date(b.date).getDate() - new Date(a.date).getDate())
                .map((item) => (
                  <div onClick={() => setAddingNewDate(false)}>
                    <FuelRow
                      key={item.date}
                      item={item}
                      editedRow={editedRow}
                      setEditedRow={setEditedRow}
                      setNewDieselPrice={setNewDieselPrice}
                      setNewPetrolPrice={setNewPetrolPrice}
                      handleFuelPriceChange={handleFuelPriceChange}
                      newDieselPrice={newDieselPrice}
                      newPetrolPrice={newPetrolPrice}
                    />
                  </div>
                ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex">
      <div className="mx-auto p-2 max-w-[900px] w-full">
        <h1 className="text-xl font-bold mb-2">
          {tSafe('components.Settings.FuelPriceSettings.edit-fuel-prices', { defaultValue: 'Edit fuel prices' })}
        </h1>

        {loadingFuelPrices ? (
          <CenteredSpinner />
        ) : addingNewDate ? (
          <div className="-mt-4">
            <FuelPriceHeaders />
            <li className={cx('flex h-5 bg-gray-200 items-center text-lg border-b border-gray-300 rounded-4')}>
              <h2 className="group flex flex-[0_0_160px] p-1 hover:cursor-pointer">
                <DatePicker
                  locale={locale}
                  selected={newDate}
                  onChange={(e) => setNewDate(e)}
                  dateFormat={'do MMMM'}
                  className="w-12 -ml-0.5 p-0.5"
                  calendarContainer={StyledCalendar}
                />
              </h2>

              <EditableInput
                onChange={(e) => setNewDieselPrice(Number(Number(e.target.value).toFixed(2)))}
                value={newDieselPrice ?? currentDieselPrice ?? ''}
              />
              <EditableInput
                onChange={(e) => setNewPetrolPrice(Number(Number(e.target.value).toFixed(2)))}
                value={newPetrolPrice ?? currentPetrolPrice ?? ''}
              />

              <IonIcon name="pencilOutline" className=" text-md ml-1 invisible group-hover:visible" />
              <div className="w-8 flex flex-end space-x-1">
                <>
                  <Button
                    onClick={() => {
                      setAddingNewDate(false);
                      handleFuelPriceChange(
                        {
                          date: newDate!.toString(),
                          petrolPrice: newPetrolPrice,
                          dieselPrice: newDieselPrice,
                        },
                        FuelPriceAction.Create,
                      );
                    }}
                    disabled={(!newDieselPrice && !newPetrolPrice) || !newDate}
                    className="px-2 py-0.5 bg-primary text-white rounded-8 hover:shadow hover:font-semibold"
                  >
                    {save_text}
                  </Button>
                  <Button onClick={() => setAddingNewDate(false)} className="p-1 text-md rounded-8 hover:bg-gray-300">
                    {cancel_text}
                  </Button>
                </>
              </div>
            </li>
          </div>
        ) : (
          <Button
            className="flex-center p-1 rounded-4 bg-success hover:font-semibold hover:shadow  text-lg mb-2"
            onClick={() => {
              setAddingNewDate(true);
              setEditedRow(undefined);
              setNewDieselPrice(currentDieselPrice);
              setNewPetrolPrice(currentPetrolPrice);
            }}
          >
            <IonIcon name="addCircleOutline" className="mr-1" />
            {tSafe('components.Settings.FuelPriceSettings.add-new-price', { defaultValue: 'Add new price' })}
          </Button>
        )}

        <FuelPriceList
          groupedData={groupByMonth(updatingFuelPrices ? previousData?.fuelPrices ?? [] : fuelPriceData ?? [])}
        />
      </div>
    </div>
  );
};

export default FuelPriceSettings;
