import { ComponentType } from 'react';
import { Layout } from 'react-grid-layout';

import * as DashboardLightEventCountWidget from 'components/Dashboard/DashboardLightEventCountWidget';
import * as DeviceStatusWidget from 'components/Dashboard/DeviceStatusWidget';
import * as DriverLeaderboardWidget from 'components/Dashboard/DriverLeaderboardWidget';
import * as DriverStatsWidget from 'components/Dashboard/FleetDriverStatsWidget';
import * as FleetHealthWidget from 'components/Dashboard/FleetHealthWidget';
import * as FuelCostsWidget from 'components/Dashboard/FuelCostsWidget';
import * as SericeStatusWidget from 'components/Dashboard/ServiceStatusWidget';
import * as UtilisationWidget from 'components/Dashboard/UtilisationWidget';
import { DashboardWidget } from 'types/widgets';

const widgetMap: Record<DashboardWidget, Partial<Layout> & { component: ComponentType }> = {
  fleetDriverStatsWidget: {
    component: DriverStatsWidget.default,
    isResizable: false,
  },
  dashboardLightEventCountWidget: {
    component: DashboardLightEventCountWidget.default,
  },
  driverLeaderboardWidget: {
    component: DriverLeaderboardWidget.default,
    minH: 6,
  },
  deviceStatusWidget: {
    component: DeviceStatusWidget.default,
    maxW: 1,
    maxH: 6,
    minH: 5,
  },
  serviceStatusWidget: {
    component: SericeStatusWidget.default,
    maxW: 1,
    maxH: 6,
    minH: 5,
  },
  fleetHealthWidget: {
    component: FleetHealthWidget.default,
    maxW: 1,
    maxH: 6,
    minH: 5,
  },
  fuelCostsWidget: {
    component: FuelCostsWidget.default,
    maxW: 2,
    maxH: 6,
    minH: 6,
  },
  utilisationWidget: {
    component: UtilisationWidget.default,
  },
};

export default widgetMap;
