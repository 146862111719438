import Tooltip from 'atoms/Tooltip';
import { RADIAN } from 'common/constants';
import { I18nContext } from 'common/useT';
import { EntityAssociation } from 'generated/graphql';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Tooltip as ChartTooltip,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Text,
  TooltipProps,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { theme } from 'twin.macro';
import { RatingColor, cx, ratingColorMap } from 'utils';

interface DonutSegment {
  text: string;
  color: RatingColor;
  value: number;
  link?: string;
}

interface DonutChartProps {
  data: DonutSegment[];
  totalCount: number;
  valueType: string;
  innerValue?: string | number;
  innerLabel?: string;
  innerValueSize?: string;
  innerValueClassName?: string;
  useLegend?: boolean;
  legendColumnCount?: number;
  mobileMargin?: { x: number; y: number };
  desktopMargin?: { x: number; y: number };
  startAngle?: number;
}

interface ActiveShapeProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  fill: string;
  name: string;
  percent: number;
  renderOnChart: boolean;
}

const DonutSegmentChart = ({
  data,
  totalCount,
  valueType,
  innerLabel,
  innerValue,
  innerValueSize = theme`fontSize.5xl`,
  innerValueClassName,
  useLegend = true,
  legendColumnCount = 3,
  mobileMargin = { x: 20, y: 40 },
  desktopMargin = { x: 40, y: 40 },
  startAngle = 305,
}: DonutChartProps) => {
  const i18nContext = useContext(I18nContext);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [legendVisible, setLegendVisible] = useState(false);
  const history = useHistory();
  const useMobileView = window.innerWidth < 768;
  const percentageThreshold = 0.08;

  useEffect(() => {
    setLegendVisible(
      useLegend &&
        donutData.length >= 3 &&
        donutData.filter((item) => item.percentage <= percentageThreshold).length >= 2,
    );
  }, [data]);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      errors: { no_data_fleet_text },
    },
  } = i18nContext;

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div>
          <div className="-mt-3 bg-white rounded-4 border-px border-gray-300 p-1">
            <span>
              <p>{`${payload?.[0]?.value} ${valueType} ${payload?.[0]?.name}`}</p>
            </span>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderLabel = ({ cx, cy, midAngle, outerRadius, name, fill, percent, renderOnChart }: ActiveShapeProps) => {
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + (outerRadius + 15) * cos;
    const my = cy + (outerRadius + 15) * sin;
    const ex = mx + (cos >= 0 ? 12 : -12);
    const ey = my + (sin >= 0 ? 10 : -10);
    const textAnchor = 'middle';

    return !legendVisible || !useLegend || (legendVisible && renderOnChart) ? (
      <g>
        <>
          <Text
            x={cos >= 0 ? ex + 10 : ex - 10 + (cos >= 0 ? 1 : -1)}
            y={ey}
            textAnchor={textAnchor}
            fill="#555"
            className="text-sm font-semibold"
            width={40}
          >
            {`${name}`}
          </Text>

          <Text
            x={cos >= 0 ? ex + 10 : ex - 10 + (cos >= 0 ? 1 : -1)}
            y={ey + 16}
            textAnchor={textAnchor}
            className="text-lg"
            color="black"
            fill="#777"
          >{`${(percent * 100).toFixed(percent >= 0.01 ? 0 : 1)}%`}</Text>
        </>
      </g>
    ) : null;
  };

  const renderActiveShape = ({
    cx: x,
    cy: y,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  }: ActiveShapeProps) => {
    return (
      <g>
        <Sector
          onClick={() =>
            data[activeIndex]?.link &&
            history.push(data[activeIndex].link!, {
              associationState: EntityAssociation.AllVehicles,
              defaultList: true,
            })
          }
          cx={x}
          cy={y}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 4}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          className={cx(data[activeIndex]?.link && 'cursor-pointer')}
        />
      </g>
    );
  };

  const donutData = data.length
    ? data.map(({ text: name, value, color, link }, index) => {
        const percentage = value / totalCount;

        return {
          index,
          name,
          percentage,
          value,
          fill: ratingColorMap[color],
          link,
          renderOnChart: percentage >= percentageThreshold,
        };
      })
    : [{ index: 0, name: '', percentage: 1, value: 100, fill: '#ccc', link: undefined, renderOnChart: true }];

  return (
    <div className="flex flex-center items-stretch flex-col w-full h-full">
      <ResponsiveContainer width="100%" height="100%">
        <PieChart
          margin={
            legendVisible
              ? { top: 20, bottom: 20, left: 20, right: 20 }
              : useMobileView
              ? { top: mobileMargin?.y, bottom: mobileMargin?.y, left: mobileMargin?.x, right: mobileMargin?.x }
              : { top: desktopMargin?.y, bottom: desktopMargin?.y, left: desktopMargin?.x, right: desktopMargin?.x }
          }
        >
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            dataKey="value"
            data={donutData}
            innerRadius="50%"
            outerRadius="100%"
            startAngle={startAngle}
            endAngle={-270}
            labelLine={false}
            label={renderLabel}
            isAnimationActive={false}
            onMouseEnter={(_, index) => setActiveIndex(index)}
            onMouseLeave={() => setActiveIndex(-1)}
          >
            {data.length ? (
              <>
                {innerLabel && <Label value={innerLabel} position="center" fontSize={theme`fontSize.md`} dy={-26} />}
                <Label
                  value={innerValue ?? totalCount}
                  position="center"
                  className={innerValueClassName}
                  fontSize={innerValueSize}
                  dy={innerLabel ? 12 : 0}
                />
              </>
            ) : (
              <Label value={no_data_fleet_text} position="center" width={100} fontSize={theme`fontSize.sm`} />
            )}
          </Pie>
          <ChartTooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>

      {useLegend && legendVisible && (
        <div
          className="flex mt-auto p-1"
          style={{ gridTemplateColumns: `repeat(${legendColumnCount}, minmax(0, 1fr))` }}
        >
          {donutData
            ?.filter((x) => !x.renderOnChart)
            .map(({ fill, name, percentage, value, index }, i) => (
              <div
                className="cursor-pointer"
                onMouseLeave={() => setActiveIndex(-1)}
                onMouseOver={() => {
                  setActiveIndex(index);
                }}
                onClick={() =>
                  data[activeIndex]?.link &&
                  history.push(data[activeIndex].link!, {
                    associationState: EntityAssociation.AllVehicles,
                    defaultList: true,
                  })
                }
              >
                <Tooltip text={<p>{`${value} ${valueType} ${name}`}</p>} className="text-sm">
                  <div className="flex-center mr-2 p-0.5">
                    <div className="h-[10px] w-[10px] mr-0.5 rounded-full" style={{ backgroundColor: fill }}></div>
                    <div className="font-semibold">{name}</div>
                  </div>
                  <div className="text-lg -mt-0.5" color="black">{`${(percentage * 100).toFixed(
                    percentage >= 0.01 ? 0 : percentage >= 0.001 ? 1 : 2,
                  )}%`}</div>
                </Tooltip>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default DonutSegmentChart;
