import { cx } from 'utils';

interface EditableInputProps {
  value: number | string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: number | string;
  onClick?: () => void;
  isEdited?: boolean;
}

const EditableInput = ({ value, onChange, defaultValue, onClick, isEdited }: EditableInputProps) => (
  <div className={cx('group grow w-4 flex-center hover:cursor-pointer')} onClick={onClick}>
    <input
      defaultValue={defaultValue}
      step="0.01"
      type="number"
      value={value}
      onChange={onChange}
      className={cx(isEdited && 'bg-gray-200', 'py-0.5 w-8 text-lg text-center outline-none rounded-4 hover:outline-2')}
    />
  </div>
);

export default EditableInput;
