import { createColumnHelper } from '@tanstack/react-table';
import { useT } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import { LeaderboardRecord, TripStatisticType, UserLocale } from 'generated/graphql';
import { RatingColor, ratingColorMap, ecoScoreRatingColorMap, ecoScoreToBandRating } from 'utils';

export enum LeaderbordColumnId {
  Ranking = 'ranking',
  Name = 'name',
  EcoScore = 'avgEcoScore',
  IdleTimePercentage = 'idleTimePercentage',
  FuelEfficiency = 'fuelEfficiency',
}

const EcoScoreCell = ({ value }: { value: number }) => (
  <div
    className="rounded-8 mx-auto text-center py-0.5 w-4 font-bold"
    style={{
      backgroundColor: !!!value
        ? ratingColorMap[RatingColor.Gray]
        : ratingColorMap[ecoScoreRatingColorMap[ecoScoreToBandRating(value)]],
    }}
  >
    {!!value ? value : 'N/A'}
  </div>
);

export const useLeaderboardColumns = () => {
  const { distanceInMiles, idleTimeAsPercentage, locale, volumeInGallons } = useSettings();
  const {
    tSafe,
    commonTranslations: {
      domain: {
        user: {
          fields: { name_text },
        },
      },
      general: { unavailable_text },
      enums: { tripStatisticTypeDescriptionMap },
    },
  } = useT();

  const columnHelper = createColumnHelper<LeaderboardRecord>();

  return [
    columnHelper.accessor<LeaderbordColumnId.Ranking, number>(LeaderbordColumnId.Ranking, {
      cell: ({ getValue }) => <div className="text-center font-semibold">#{getValue()}</div>,
      id: LeaderbordColumnId.Ranking,
      header: () => tSafe('components.Dashboard.fleetDriverStatsWidget.rank', { defaultValue: 'Rank' }),
      enableSorting: false,
    }),
    columnHelper.accessor<LeaderbordColumnId.Name, string>(LeaderbordColumnId.Name, {
      cell: ({ getValue }) => <div className="text-center">{getValue()}</div>,
      id: LeaderbordColumnId.Name,
      header: () => name_text,
      enableSorting: false,
    }),
    columnHelper.accessor<LeaderbordColumnId.EcoScore, number>(LeaderbordColumnId.EcoScore, {
      cell: ({ getValue }) => <EcoScoreCell value={getValue()} />,
      id: LeaderbordColumnId.EcoScore,
      header: () => tripStatisticTypeDescriptionMap[TripStatisticType.EcoScore],
    }),
    columnHelper.accessor<LeaderbordColumnId.IdleTimePercentage, number>(LeaderbordColumnId.IdleTimePercentage, {
      cell: ({ getValue }) => <div className="text-center">{getValue()}%</div>,
      id: LeaderbordColumnId.IdleTimePercentage,
      header: () => tSafe('components.Dashboard.fleetDriverStatsWidget.idle-time', { defaultValue: 'Idle time' }),
    }),
    columnHelper.accessor<LeaderbordColumnId.FuelEfficiency, number>(LeaderbordColumnId.FuelEfficiency, {
      cell: ({ getValue }) => (
        <div className="text-center">
          {!!getValue() ? (
            `${getValue()} ${distanceInMiles ? 'm' : 'k'}p${volumeInGallons ? 'g' : 'l'}`
          ) : (
            <span className="text-gray-400">{unavailable_text}</span>
          )}
        </div>
      ),
      id: LeaderbordColumnId.FuelEfficiency,
      header: () =>
        tSafe('components.Dashboard.fleetDriverStatsWidget.fuel-efficiency', { defaultValue: 'Fuel efficiency' }),
    }),
  ];
};
